import { GetStaticProps } from "next";
import { useTranslations } from "next-intl";
import Layout from "../components/common/Layout";
import Container from "../components/container/Container";
import * as animationData from "../animations/404.json";
import Lottie from "react-lottie";

const ANIM_SIZE = 650;

export default function Custom404() {
  const translate = useTranslations();
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
  };

  return (
    <Layout pageTitle={translate("pageNotFound") as string}>
      <Container>
        <div className="w-full flex items-center flex-col">
          <Lottie options={defaultOptions} width={ANIM_SIZE} />
          <span className="text-red-500 text-xl font-semibold mt-300 absolute">
            {translate("pageNotFound")}
          </span>
        </div>
      </Container>
    </Layout>
  );
}

export const getStaticProps: GetStaticProps = async ({ locale }) => {
  return {
    props: {
      messages: {
        ...require(`../translations/${locale}.json`),
      },
    },
  };
};
